import React from "react";
import "./style.css";
import manageMenu from "./img/manage-menu.png";

export const ManageYourProducts = props => {
  return (
    <section className="manage-your-products" id="manage-your-products">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="section-heading text-center">
              <h2><strong>Manage your products</strong></h2>
              <p>
                {`Live in a management utopia. Your handy dashboard allows you to manage your stock, auto-update your inventory and present your products in a sleek menu for your diners.`}
              </p>
            </div>
            <div className="accordion" id="manage-your-products">
              <h3 className="clickable" id="headingOne">
                <button className="btn" type="button" data-toggle="collapse" data-target="#update-menu" >
                  <strong>{`Update your menu anytime, anywhere`}</strong>
                </button>
              </h3>
              <div id="update-menu" className="collapse show" aria-labelledby="headingOne" data-parent="#manage-your-products">
                <p>{`Spotted a typo on your menu? An item image isn’t where it’s supposed to be or need to add multiple images? No need to make a billion calls for a simple task, just tap into your advanced item settings and tweak away.`}</p>
              </div>
              <h3 className="clickable" id="headingTwo">
                <button className="btn" type="button" data-toggle="collapse" data-target="#update-availability" >
                  <strong>{`Update your menu item availability`}</strong>
                </button>
              </h3>
              <div id="update-availability" className="collapse" aria-labelledby="headingTwo" data-parent="#manage-your-products">
                <p>{`Mark an item as sold out`}</p>
              </div>
              <h3 className="clickable" id="headingThree">
                <button className="btn" type="button" data-toggle="collapse" data-target="#update-preparation-time" >
                  <strong>{`Set extra preparation time`}</strong>
                </button>
              </h3>
              <div id="update-preparation-time" className="collapse" aria-labelledby="headingThree" data-parent="#manage-your-products">
                <p>{`Special dishes take time. You don’t want a frustrated customer. Adjust your preparation times for every unique item, and display it on your menu.`}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <img class="img-fluid" src={manageMenu} alt="Manage menu" />
          </div>
        </div>

      </div>
    </section>
  );
};