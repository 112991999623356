import React, { Component } from "react";
import { FeaturesComponent } from "./FeaturesComponent/FeaturesComponent";

class Pricing extends Component {
  render() {
    return (
      <div>
 

        <FeaturesComponent />


      </div>
    );
  }
}

export default Pricing;
