import React, { Component } from "react";

import "../App.css";
import "font-awesome/css/font-awesome.css";

import { FeaturesComponent } from "./FeaturesComponent/FeaturesComponent";

import { HeroComponent } from "./HeroComponent/HeroComponent";

import {PromotionsComponent} from "./Promotions/PromotionsComponent";
import {DashboardComponent} from "./Dashboard/DashboardComponent";
import { TestimonialComponent } from "./Testimonial/TestimonialComponent";

class Home extends Component {
  render() {
    return (
      <div>
 
        <HeroComponent />
        <PromotionsComponent />
        <DashboardComponent />
        <FeaturesComponent />
        <TestimonialComponent />
        {/* <CallToActionComponent />
        <LogoGridComponent />
        <SignUpComponent />
        <SocialComponent /> */}

      </div>
    );
  }
}

export default Home;
