import React from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";


export const ActivateOnlinePayment = props => {
  return (
    <section className="activate-online-payment" id="activate-online-payment">
      <div className="container">
        <div className="section-heading text-center">
          <h2><strong>Activate online payment</strong></h2>
          <p>
            {`Your storefront is equipped with plenty of payment gateways. VISA, MasterCard, America Express,  -your customer can choose.`}
          </p>
          <hr />
        </div>
        <div className="card-deck mb-3">
          <div className="card">
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>A wide array of payment options</strong></h3>
              </div>
              <p>Your storefront is equipped with plenty of payment gateways. Cash, credit card, Knet, apple pay- your customer can choose.</p>

            </div>
          </div>
          <div className="card">
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Minimal transaction fees</strong></h3>
              </div>
              <p>Say goodbye to sky-high transaction fees that eat up your sales. We minimize the costs. Enjoy the lowest ever transaction fees.</p>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Flexible menu layout</strong></h3>
              </div>
              <p>Your menu is the face of your restaurant. Don't worry, the menu you're used to is the menu you get. Choose your favoriate layout to best display your dishes.</p>

            </div>
          </div>
        </div>


      </div>
    </section>
  );
};
