import React from "react";
import "./style.css";

export const OwnAndGrowYourConsumerBase = props => {
  return (
    <section className="own-and-grow-your-consumer-base" id="own-and-grow-your-consumer-base">
      <div className="container">
        <div className="section-heading text-center">
          <h2><strong>Own & Grow your consumer base</strong></h2>
          <p>
            {`Going online naturally broadens your landscape. Find out who’s been waiting to hear about your brand. Use your built-in marketing toolkit to understand your audience and establish a loyal consumer base.`}
          </p>
        </div>
        <div className="accordion" id="own-and-grow-your-consumer-base">
          <h3 className="clickable" id="headingOne">
            <button className="btn" type="button" data-toggle="collapse" data-target="#order-panel" >
              <strong>{`Google analytics`}</strong>
            </button>
          </h3>
          <div id="order-panel" className="collapse show" aria-labelledby="headingOne" data-parent="#own-and-grow-your-consumer-base">
            <p>{`No more marketing in the dark, your restaurant has been online for a while, and it’s time to find out how well it’s doing. Integrate with Google Analytics & Facebook pixel tracker and analyze the results of your online journey. Use this data to make smarter decisions about your business.`}</p>
          </div>
          <h3 className="clickable" id="headingTwo">
            <button className="btn" type="button" data-toggle="collapse" data-target="#scheduled-orders" >
              <strong>{`Flexible promotion and coupon system`}</strong>
            </button>
          </h3>
          <div id="scheduled-orders" className="collapse" aria-labelledby="headingTwo" data-parent="#own-and-grow-your-consumer-base">
            <p>{`Now the fun part. Your vouchers are designed to leave room for brand creativity Vouchers for new menu items, happy hour vouchers, holiday vouchers- Create volumes of your most clever coupons and stash them away till the time is right.`}</p>
          </div>
          <h3 className="clickable" id="headingThree">
            <button className="btn" type="button" data-toggle="collapse" data-target="#order-status-update" >
              <strong>{`Built-in SMS marketing tool`}</strong>
            </button>
          </h3>
          <div id="order-status-update" className="collapse" aria-labelledby="headingThree" data-parent="#own-and-grow-your-consumer-base">
            <p>{`Established your brand? Got your vouchers ready? Seal the deal with an elaborate SMS marketing campaign. Bring in the sales and earn your customers’ loyalty.`}</p>
          </div>
        </div>
      </div>
    </section>
  );
};