import React from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";

export const FeaturesComponent = props => {
  return (
    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Features</h2>
          <p className="text-muted">

          </p>
          <hr />
        </div>
        <div className="card-deck mb-3">
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title">
                <h3><strong>Brand your platform</strong></h3>
              </div>
              <p>Touch up our's sleek layout with your brand's very own colors and images. With MyFoodCourt, you get your own stage, and you also get to decorate it. Get creative. You've got multiple features to help you dress up your store</p>
              <a href="/features#brand-your-platform">Learn More <i className="fas fa-chevron-circle-right mr-1" /></a>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title">
                <h3><strong>Manage your products</strong></h3>
              </div>
              <p>Live in management utopia. Your handy dashboard allows you to manage your stock, auto-update your inventory and present your products in a sleek menu for your customers.</p>
              <a href="/features#manage-your-products">Learn More <i className="fas fa-chevron-circle-right mr-1" /></a>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title">
                <h3><strong>Streamline your orders</strong></h3>
              </div>
              <p>Watch your order operations become one synchronized process. No more running around chasing orders, making sure everything is going smoothly. Your orders are gathered, organized and accelerated from start to finish.</p>
              <a href="/features#streamline-your-orders">Learn More <i className="fas fa-chevron-circle-right mr-1" /></a>
            </div>
          </div>
        </div>
        <div className="card-deck mt-3">
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title">
                <h3><strong>Supervise your branches and teams</strong></h3>
              </div>
              <p>Run your restaruants even while on-the-go. Manage your branch settings, your team communications and your workflow throught a simple web app. Save your time and spend the rest of it planning the future of your business.</p>
              <a href="/features#supervise-your-branches-and-team">Learn More <i className="fas fa-chevron-circle-right mr-1" /></a>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title">
                <h3><strong>Promotions and coupons</strong></h3>
              </div>
              {/* <p>Run your restaruants even while on-the-go. Manage your branch settings, your team communications and your workflow throught a simple web app. Save your time and spend the rest of it planning the future of your business.</p> */}
              {/* <a href="">Learn More <i className="fas fa-chevron-circle-right mr-1" /></a> */}
            </div>
          </div>
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title">
                <h3><strong>Online payments</strong></h3>
              </div>
              <p>Make payment instant for your customers. Built by spripe the most secure online payment platform offer you speed, fluidity and maximized profits.</p>
              <a href="/features#activate-online-payment">Learn More <i className="fas fa-chevron-circle-right mr-1" /></a>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};
