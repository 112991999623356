import React, { Component } from "react";


import "./App.css";
import "font-awesome/css/font-awesome.css";

import { FooterComponent } from "./components/FooterComponent/FooterComponent";

import NavBarComponent from "./components/NavBarComponent/NavBarComponent";

import Main from "./components/Main";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { navBarShrink: "" };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    // const domNode = ReactDOM.findDOMNode(this.navEl);
    const nbs = window.pageYOffset > 100 ? "navbar-shrink" : "";
    this.setState({ navBarShrink: nbs });
  }

  render() {
    return (
      <div>
        <NavBarComponent navBarShrink={"navbar-shrink"} />
        <Main />

        <FooterComponent />
      </div>
    );
  }
}

export default App;
