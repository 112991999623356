import React, { Component } from "react";
import { BrandYourPlatform } from "./Features/BrandYourPlatform";
import { StreamlineYourOrders } from "./Features/StreamlineYourOrders";
import { ManageYourProducts } from "./Features/ManageYourProducts";
import { MonitorAndAnalyzeYourGrowth } from "./Features/MonitorAndAnalyzeYourGrowth";
import { OwnAndGrowYourConsumerBase } from "./Features/OwnAndGrownYourConsumerBase";
import { SuperviseYourBranchesAndTeam } from "./Features/SuperviseYourBranchesAndTeam";
import { ActivateOnlinePayment } from "./Features/ActivateOnlinePayment";
class Features extends Component {
  render() {
    return (
      <div>
        <BrandYourPlatform />
        <ManageYourProducts />
        <StreamlineYourOrders />
        <SuperviseYourBranchesAndTeam />

        <OwnAndGrowYourConsumerBase />
        <MonitorAndAnalyzeYourGrowth />

        <ActivateOnlinePayment />
      </div>
    );
  }
}

export default Features;
