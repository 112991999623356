import { getData, postData } from './index';

import {
  REGISTER_TENANT,
  FETCH_TENANTS,
  TENANT_ERROR,
} from './types';

export function fetchTenants() {
  const url = process.env.REACT_APP_API_BASE + `/api/public/tenants`;
  return dispatch => getData(FETCH_TENANTS, TENANT_ERROR, url, dispatch);
}

export function register({restaurantName, firstName, lastName, email, password, telephone}, callback) {
  const data = {
    restaurantName, firstName, lastName, email, password, telephone
  };
  console.log(data)
  const url = process.env.REACT_APP_API_BASE + `/api/public/tenants/register`;
  return dispatch =>
    postData(REGISTER_TENANT, TENANT_ERROR, url, dispatch, data, callback);

}

