import axios from 'axios';

export function errorHandler(dispatch, error, type) {

  let errorMessage = error.data;

  dispatch({
    type,
    payload: errorMessage
  });
}


// Get Request
export function getData(action, errorType, url, dispatch) {
  let headers = {};

  axios.get(url, headers)
    .then((response) => {

      dispatch({
        type: action,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response) {
        errorHandler(dispatch, error.response, errorType);
      }
    });
}

// Post Request
export function postData(
  action,
  errorType,
  url,
  dispatch,
  data,
  callback
) {
  let headers = {};

  axios
    .post(url, data, headers)
    .then(response => {
      callback && callback();
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      errorHandler(dispatch, error.response, errorType);
    });
}