import React, { useState } from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";
import AliceCarousel from "react-alice-carousel";

const testimonials = [
  {
    "name": "Lynn Chen",
    "restaurant": "Angkor Southeast Asian Delight",
    "testimonial": "“As their frist client, MyFoodCourt has done a lot for my business. It saved us commisions and it saved us time.”"
  }
]

export const TestimonialComponent = props => {
  const [activeIndex] = useState(0);
  return (
    <section className="testimonials" id="testimonials">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Our customers' experience</h2>
          <p className="text-muted">

          </p>
          <hr />
        </div>
        <AliceCarousel
          autoHeight={false}
          disableDotsControls={true}
          disableButtonsControls={true}
          responsive={
            {
              0: {
                items: 2,
              },
            }
          }
          activeIndex={activeIndex}

        >
          {testimonials.map((testimonial, index) => (
            <div className="item" key={index}>
              <p>{testimonial.testimonial}</p>
              <p>{testimonial.name}</p>
              <p>{testimonial.restaurant}</p>
            </div>
          ))}
        </AliceCarousel>

      </div>
    </section>
  );
};
