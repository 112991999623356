import React from "react";
import { Switch, Route } from "react-router-dom";

import "./Main.css";

import Home from "./Home";
import Features from "./Features";
import Pricing from "./Pricing";
import Register from "./Register";
import NotFound from "./NotFound";

const Main = () => {
  return (
    <div className="main-content">
      {/* <Router history={history}> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/features" component={Features} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/register" component={Register} />
          <Route path="*" component={NotFound} />
        </Switch>
      {/* </Router> */}
    </div>
  )
};

export default Main;