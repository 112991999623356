import {
  FETCH_TENANTS,
  TENANT_ERROR
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: ""
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TENANTS:
      return { ...state, tenants: action.payload.tenants };
    case TENANT_ERROR:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}
