import React from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";
import salesPerformanceByDay from "./img/sales-performance-by-day.png";
import productsPerformanceByDay from "./img/products-performance-by-day.png";

export const MonitorAndAnalyzeYourGrowth = props => {
  return (
    <section className="monitor-and-analyze" id="monitor-and-analyze">
      <div className="container">
        <div className="section-heading text-center">
          <h2><strong>Monitor & analyze your growth</strong></h2>
          <p>
            {`Plan for the future with sales analytics. Your dashboard records all your online dealings, and it analyzes it too. No better way to tailor your business than using the insights of an organized beast.`}
          </p>
          <hr />
        </div>
        <div className="card-deck mb-3">
          <div className="card">
            <img class="card-img-top" src={salesPerformanceByDay} alt="Sales performance by day" />
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Sales performance by time period</strong></h3>
              </div>
              <p>Find out how well your restaurant is doing. Compare your sales patterns per hour, per day, monthly or annually.</p>

            </div>
          </div>
          <div className="card">
          <img class="card-img-top" src={productsPerformanceByDay} alt="Products performance by day" />
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Product performance by time period</strong></h3>
              </div>
              <p>Evaluate your entire menu with product analytics. Learn about your most and least lucrative products- per day, monthly or annually. It’s the perfect tool for testing out experimental dishes.</p>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Customers Behavior Statistics</strong></h3>
              </div>
              <p>Make use of all the other extra stats. Number of customers, average order amount, total monthly sales- You’d be surprised how much you could learn about your business.</p>

            </div>
          </div>
        </div>


      </div>
    </section>
  );
};
