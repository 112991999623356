import React from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";
import history from "../history";

export const DashboardComponent = props => {
  return (
    <section className="dashboard" id="dashboard">
      <div className="container">
        <div className="section-heading text-center">
          <h2>{`Better management, more orders`}</h2>
          <p className="text-muted">
            {`Simplify your operations with a powerful dashboard`}
          </p>
          <hr />
        </div>
        <ul className="list-unstyled">
          <li>
            <h3>{`Order management made easy`}</h3>
            <p>{`From start to finish - all orders are gathered, organized and controlleed on one interface.`}</p>
          </li>
          <li>
            <h3>{`Advanced sales analytics`}</h3>
            <p>{`Study your patterns and planf or your restaurant's future using the insights of your analytics toolkit.`}</p>
          </li>
          <li>
            <h3>{`Built-in marketing tools`}</h3>
            <p>{`Your altimate growth hacker - q handy marketing and communication tool, creating meaningful relationships between restaurant and customer. Google analytics, Flexibile promotions and coupons.`}</p>
          </li>
          <li>
            <h3>{`Powerful dashboard`}</h3>
            <p>{`Give your a summary view of key metrics.`}</p>
          </li>
        </ul>
        <button className="btn btn-primary" onClick={() => history.push({pathname: "/register", state: {email: ""}})}>{`Sign Up`}</button>
      </div>

    </section>
  );
};
