import React from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";
import history from "../history";

export const PromotionsComponent = props => {
  return (
    <section className="promotions" id="promotions">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Your Online Storefront</h2>
          <p className="text-muted">
          Give your customers a seamless online ordering experience. 
          </p>
          <hr />
        </div>
        <ul className="list-unstyled">
              <li>
                <h3>Your very own domain name</h3>
                <p>An ordering platform in the name of our brand. Easier to remember, establishes credibility.</p>
              </li>
              <li>
                <h3>Various delivery and pickup options</h3>
                <p>Support delivery and pickup. Stay ahead in the age of convenience.</p>
              </li>
              <li>
                <h3>Single sign-in & quick reorder</h3>
                <p>Ordered once? Your customer's details are instantly saved. Their second order will be easier than the first - the quick reorder button is right there.</p>
              </li>
        </ul>
        <button className="btn btn-primary" onClick={() => history.push({pathname: "/register", state: {email: ""}})}>{`Sign Up`}</button>
      </div>
     
    </section>
  );
};
