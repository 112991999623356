import React, { Component } from "react";
import "./style.css";
import { Events, animateScroll as scroll, scrollSpy } from "react-scroll";
import { NavLink } from "react-router-dom";
import foodcourt_img_small from './foodcourt_small.png';

class NavBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { menuShow: false };
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", () => {

      this.closeMenu();
    });

    Events.scrollEvent.register("end", function () {

    });
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  toggleShow() {
    this.setState(prevState => {
      return { menuShow: !prevState.mnuShow };
    });
  }

  closeMenu() {
    if (this.state.menuShow) {
      this.setState({ menuShow: false });
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  render() {
    const show = this.state.menuShow ? "show" : "";
    return (
      <nav
        className={`navbar navbar-expand-lg navbar-dark fixed-top ${this.props.navBarShrink
          }`}
        id="mainNav"
      >
        <div className="container">
          <a
            onClick={this.scrollToTop.bind(this)}
            className="navbar-brand js-scroll-trigger"
            href="/"
          >
            <img src={foodcourt_img_small} className="img-fluid mr-1" alt="MyFoodCourt" />MyFoodCourt
          </a>
          <button
            onClick={this.toggleShow.bind(this)}
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >

            <i className="fa fa-bars" />
          </button>
          <div
            className={`collapse navbar-collapse ${show}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/features">
                  {`Features`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/pricing">
                  {`Pricing`}
                </NavLink>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a href={process.env.REACT_APP_LOGIN_URL}>
                  {`Login`}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
export default NavBarComponent;
