import { combineReducers } from 'redux';  
import { reducer as formReducer } from 'redux-form';  
import { connectRouter } from 'connected-react-router';
import tenantReducer from './tenant_reducer';

const rootReducer = (history) => combineReducers({  
  router: connectRouter(history),
  form: formReducer,
  tenant: tenantReducer,
});

export default rootReducer;  
