import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { register } from "../actions/tenant";
import normalizePhone from "../utils/normalizePhone";
import {
  renderUserNameField,
  renderEmailField,
  renderPhoneField,
  renderPasswordField,
  renderField,
  required,
  phoneNumber,
  email,
} from "../utils/renderFields";

const form = reduxForm({
  form: "register",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = "Please enter a first name";
  }

  if (!formProps.lastName) {
    errors.lastName = "Please enter a last name";
  }

  if (!formProps.email) {
    errors.email = "Please enter an email";
  }

  if (!formProps.telephone) {
    errors.telephone = "Please enter telephone number";
  }

  if (!formProps.password) {
    errors.password = "Please enter a password";
  }

  return errors;
}

class Register extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(formProps) {
    // sendGAEvent({
    //   category: "Auth",
    //   action: "Register Form Submit",
    //
    //   label: `${formProps.firstName} ${formProps.lastName}`
    // });
    this.props.register(formProps, () => {
      this.setState({ redirectToReferrer: true });
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <div className="row pt-3">
        <div className="col-10 offset-1 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <h3 className="text-center">
            <strong>{`Let's get you started`}</strong>
          </h3>
          <form onSubmit={handleSubmit(this.handleFormSubmit)}>
            {this.renderAlert()}
            <div className="form-group">
              <label className="required">Restaurant Name</label>
              <Field
                name="restaurantName"
                className="form-control"
                component={renderField}
                validate={[required]}
                type="text"
              />
            </div>
            <div className="form-group">
              <label className="required">First Name</label>
              <Field
                name="firstName"
                className="form-control"
                component={renderUserNameField}
                validate={[required]}
                type="text"
              />
            </div>
            <div className="form-group">
              <label className="required">Last Name</label>
              <Field
                name="lastName"
                className="form-control"
                component={renderUserNameField}
                validate={[required]}
                type="text"
              />
            </div>

            <div className="form-group">
              <label className="required">Email</label>
              <Field
                name="email"
                className="form-control"
                component={renderEmailField}
                validate={[required, email]}
                type="email"
              />
            </div>
            <div className="form-group">
              <label className="required">Password</label>
              <Field
                name="password"
                className="form-control"
                component={renderPasswordField}
                type="password"
              />
            </div>
            <div className="form-group">
              <label className="required">Phone</label>
              <Field
                name="telephone"
                className="form-control"
                component={renderPhoneField}
                placeholder="___-___-____"
                validate={[required, phoneNumber]}
                type="text"
                normalize={normalizePhone}
              />
            </div>
            <div className="controls text-center">
              <button
                type="submit"
                disabled={pristine || submitting}
                className="btn btn-primary"
              >
                {`Sign Up`}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    initialValues: {
      email: ownProps.location.state.email,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  register: (formProps, callback) => dispatch(register(formProps, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Register));
