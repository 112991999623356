import React from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";


export const BrandYourPlatform = props => {
  return (
    <section className="brand-your-platform" id="brand-your-platform">
      <div className="container">
        <div className="section-heading text-center">
          <h2><strong>Brand your platform</strong></h2>
          <p>
            {`Adjust the look and feel of your brand.`}
          </p>
          <hr />
        </div>
        <div className="card-deck mb-3">
          <div className="card">
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Free domain. It's on the house.</strong></h3>
              </div>
              <p>You read that right. Getting your own domain is built into your package. It's no longer a financial milestone to work up to.</p>

            </div>
          </div>
          <div className="card">
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Personalized themes</strong></h3>
              </div>
              <p>You want a platform that feels like you. We'v got you. Play around with your colors and cover image till it brings out the spirit of your brand.</p>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Flexible menu layout</strong></h3>
              </div>
              <p>Your menu is the face of your restaurant. Don't worry, the menu you're used to is the menu you get. Choose your favoriate layout to best display your dishes.</p>

            </div>
          </div>
        </div>


      </div>
    </section>
  );
};
