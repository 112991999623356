import React from "react";
import "./style.css";
import structuredOrderPanel from "./img/structured-order-panel.png";

export const StreamlineYourOrders = props => {
  return (
    <section className="streamline-your-orders" id="streamline-your-orders">
      <div className="container">
      <div className="row">
          <div className="col-12 col-lg-6">
        <div className="section-heading text-center">
          <h2><strong>Streamline your orders</strong></h2>
          <p>
            {`Watch your order operations become one synchronized process. No more running around chasing orders, making sure everything is going smoothly. Your orders are gathered, organized and accelerated from start to finish.`}
          </p>
        </div>
        <div className="accordion" id="streamline-your-orders">
          <h3 className="clickable" id="headingOne">
            <button className="btn" type="button" data-toggle="collapse" data-target="#order-panel" >
              <strong>{`Intuitively-structured order panel`}</strong>
            </button>
          </h3>
          <div id="order-panel" className="collapse show" aria-labelledby="headingOne" data-parent="#streamline-your-orders">
            <p>{`Losing track of your orders is a thing of the past. Your order panel fills up all neat and sequenced. Chronological, organized and sortable.`}</p>
          </div>
          <h3 className="clickable" id="headingTwo">
            <button className="btn" type="button" data-toggle="collapse" data-target="#scheduled-orders" >
              <strong>{`Scheduled orders`}</strong>
            </button>
          </h3>
          <div id="scheduled-orders" className="collapse" aria-labelledby="headingTwo" data-parent="#streamline-your-orders">
            <p>{`Don’t let your restaurant’s hours shackle anyone. Your customers can schedule their orders to arrive at their most convenient date and time.`}</p>
          </div>
          <h3 className="clickable" id="headingThree">
            <button className="btn" type="button" data-toggle="collapse" data-target="#order-status-update" >
              <strong>{`Order status updates`}</strong>
            </button>
          </h3>
          <div id="order-status-update" className="collapse" aria-labelledby="headingThree" data-parent="#streamline-your-orders">
            <p>{`Curb your customer’s excitement by updating their order status on your dashboard. They’ll be instantly notified via email or SMS.`}</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6">
      <img class="img-fluid" src={structuredOrderPanel} alt="Order panel" />
      </div>
      </div>
      </div>
    </section>
  );
};