import React from "react";
import "./style.css";
import "simple-line-icons/css/simple-line-icons.css";
import branchSettings from "./img/branch-settings.png";
import deliverySettings from "./img/delivery-settings.png";

export const SuperviseYourBranchesAndTeam = props => {
  return (
    <section className="supervise-your-branches-and-team" id="supervise-your-branches-and-team">
      <div className="container">
        <div className="section-heading text-center">
          <h2><strong>Supervise your branches & team</strong></h2>
          <p>
            {`Run your restaurant while on-the-go. Manage your branch settings, your team communications and your workflow through a simple mobile app. Save your time and spend the rest of it planning the future of your business.`}
          </p>
          <hr />
        </div>
        <div className="card-deck mb-3">
          <div className="card">
          <img class="card-img-top" src={branchSettings} alt="Branch settings" />
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Adjust your branch settings</strong></h3>
              </div>
              <p>Each restaurant branch has its own circumstances and its own needs. Lucky for you, it’s a flexible system. You can add multiple branches, set their working hours, set them to busy if needed and adjust their delivery, pick-up or dine-in settings.</p>

            </div>
          </div>
          <div className="card">
          
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Dashboard for every job role</strong></h3>
              </div>
              <p>Your dashboard is tailored to your role in the business: owner, manager, operator and delivery driver. No need to overwhelm your staff with features they’ll never use.</p>
            </div>
          </div>
          <div className="card">
          <img class="card-img-top" src={deliverySettings} alt="Delivery settings" />
            <div className="card-body text-left">
              <div className="card-title">
                <h3><strong>Map out your delivery zones</strong></h3>
              </div>
              <p>Your delivery settings are adjustable to every branch’s convenience. Choose your delivery zones, adjust your price per radius, and create exceptions for areas where delivery is tricky.</p>

            </div>
          </div>
        </div>


      </div>
    </section>
  );
};
