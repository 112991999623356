import React, { useState } from "react";
import "./style.css";
import "../../device-mockups/device-mockups.css";
// import demoScreen1 from "../../demo-screen-1.jpg";
import demoScreen1 from "./img/online-order.png";
import history from "../history";

export const HeroComponent = props => {
  const [email, setEmail] = useState("");
  return (
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-3">
                {`TAKE BACK CONTROL`}
              </h1>
              <h3 className="mb-5">{`Empower your restaurant with your own online ordering service. Ready in 24 hours! No more hefty 3rd party fees!`}</h3>
              <h4>{`Sign up for 1-month free trial!`}</h4>
              <div className="input-group">
                <input type="text" className="form-control mr-1" id="email" placeholder="Email"  onChange={(event) => setEmail(event.target.value)} value={email}/>
                <button type="button" className="btn btn-primary"  onClick={() => history.push({pathname: "/register", state: {email: email}})}>{`Sign up`}</button>
              </div>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demoScreen1} className="img-fluid" alt="" />
                  </div>
                  <div className="button" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
